import { BaseApi } from './BaseApi';

export default class Account extends BaseApi {
  public async authenticateAsync() {
    return this.postAsync<IAuthenticationResult>('account/authenticate');
  }

  public async signInAsync(username: string, password: string) {
    return this.postAsync<IAuthenticationResult>('account/signin', { username, password });
  }

  public async signInWithTokenAsync(token: string) {
    return this.postAsync<IAuthenticationResult>('account/signin', { token });
  }

  public async signOutAsync() {
    return this.postAsync<void>('account/signout');
  }

  public async resetPasswordAsync(username: string) {
    return this.postAsync<void>('account/resetpassword', { username });
  }
}
