import Vue from 'vue'
import Router from 'vue-router'
import TestPage from './views/TestPage.vue'
import ComponentSwitcher from './components/ComponentSwitcher.vue'
import PageNotFound from './views/PageNotFound.vue'

Vue.use(Router)

Vue.component('home', () =>
  import(/* webpackChunkName: "desktop" */ './views/Home.vue')
)
Vue.component('ad', () =>
  import(/* webpackChunkName: "desktop" */ './views/Ad.vue')
)
Vue.component('items', () =>
  import(/* webpackChunkName: "desktop" */ './views/Items.vue')
)
Vue.component('coupons', () =>
  import(/* webpackChunkName: "desktop" */ './views/Coupons.vue')
)

Vue.component('i-home', () =>
  import(/* webpackChunkName: "iframe" */ './views/iframe/Home.vue')
)

Vue.component('m-home', () =>
  import(/* webpackChunkName: "mobile" */ './views/mobile/Home.vue')
)
Vue.component('m-ad', () =>
  import(/* webpackChunkName: "mobile" */ './views/mobile/Ad.vue')
)
Vue.component('m-items', () =>
  import(/* webpackChunkName: "mobile" */ './views/mobile/Items.vue')
)
Vue.component('m-coupons', () =>
  import(/* webpackChunkName: "mobile" */ './views/mobile/Coupons.vue')
)

export default new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'root',
      component: TestPage
    },
    {
      path: '/:storeRoute/admin',
      component: () =>
        import(/* webpackChunkName: "admin" */ './views/Admin.vue'),
      props: true,
      children: [
        {
          path: '',
          name: 'adminDashboard',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ './components/AdminDashboard.vue'
            )
        },
        {
          path: 'ads',
          name: 'adminAds',
          component: () =>
            import(/* webpackChunkName: "admin" */ './components/AdminAds.vue')
        },
        {
          path: 'items',
          name: 'adminItems',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "admin" */ './components/AdminItems.vue'
            )
        },
        {
          path: 'social-media',
          name: 'adminSocialMedia',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "admin" */ './components/AdminSocialMedia.vue'
            )
        },
        {
          path: 'media-studio',
          name: 'adminMediaStudioAccounts',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "admin" */ './components/AdminMediaStudioAccounts.vue'
            )
        },
        {
          path: 'store',
          name: 'adminStore',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ './components/AdminStore.vue'
            )
        },
        {
          path: 'banner-ads',
          name: 'adminBannerAds',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ './components/AdminBannerAds.vue'
            )
        }
      ]
    },
    {
      path: '/:storeRoute',
      component: ComponentSwitcher,
      props: route => ({
        storeRoute: route.params.storeRoute,
        token: route.query.token,
        chooseStore: route.query.chooseStore !== undefined,
        desktop: 'home',
        mobile: 'm-home',
        iframeDesktop: 'i-home'
      }),
      children: [
        {
          path: '',
          name: 'home',
          component: ComponentSwitcher,
          props: {
            desktop: () =>
              import(
                /* webpackChunkName: "desktop" */ './components/desktop/AdList.vue'
              ),
            mobile: () =>
              import(
                /* webpackChunkName: "mobile" */ './components/mobile/AdList.vue'
              )
          }
        },
        {
          path: 'coupons',
          name: 'coupons',
          components: {
            default: ComponentSwitcher,
            toolbar: () =>
              import(
                /* webpackChunkName: "mobile" */ './components/mobile/CouponsToolbar.vue'
              )
          },
          props: {
            default: {
              desktop: 'coupons',
              mobile: 'm-coupons'
            }
          }
        },
        {
          path: 'items',
          name: 'items',
          components: {
            default: ComponentSwitcher,
            toolbar: () =>
              import(
                /* webpackChunkName: "mobile" */ './components/mobile/ItemsToolbar.vue'
              )
          },
          props: {
            default: {
              desktop: 'items',
              mobile: 'm-items'
            }
          }
        },
        {
          path: ':adRoute',
          name: 'ad',
          components: {
            default: ComponentSwitcher,
            toolbar: () =>
              import(
                /* webpackChunkName: "mobile" */ './components/mobile/AdToolbar.vue'
              )
          },
          props: {
            default: route => ({
              adRoute: route.params.adRoute,
              desktop: 'ad',
              mobile: 'm-ad'
            })
          }
        }
      ]
    },
    {
      path: '*',
      component: PageNotFound
    }
  ]
})
