import { render, staticRenderFns } from "./ComponentSwitcher.vue?vue&type=template&id=0e1c7158&"
import script from "./ComponentSwitcher.vue?vue&type=script&lang=ts&"
export * from "./ComponentSwitcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\dev\\src\\repos\\GitHub\\adviewer\\AdViewer.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e1c7158')) {
      api.createRecord('0e1c7158', component.options)
    } else {
      api.reload('0e1c7158', component.options)
    }
    module.hot.accept("./ComponentSwitcher.vue?vue&type=template&id=0e1c7158&", function () {
      api.rerender('0e1c7158', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "wwwroot/src/components/ComponentSwitcher.vue"
export default component.exports